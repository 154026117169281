import parse from 'date-fns/parse';
import format from 'date-fns/format';
import {getProp, splitPathname} from '../../lib/utils';
import Link from 'next/link';
import {TagCluster} from '../ContentTags';
import {BREAKPOINT} from '../../lib/styles';
import url from 'url';
import {getFormattedDate, replaceHtmlEntities} from 'aac-components/utils/helpers';

const BlogCard = ({post}) => {
    const href = post?.link && url.parse(post?.link)?.pathname || '/';

    return (
        <div className="blog-card">
            <Link href={href} title={replaceHtmlEntities(post?.title?.rendered || '')}>
                <div
                    className="blog-card__title"
                    dangerouslySetInnerHTML={{__html: post?.title?.rendered || ''}}
                />
            </Link>
            <span className="blog-card__date">{getFormattedDate(post?.date)}</span>
            <div
                className="blog-card__excerpt"
                dangerouslySetInnerHTML={{__html: post?.excerpt?.rendered || ''}}
            />
            <div className="blog-card__tags">
                <TagCluster tags={post?.tags || []} />
            </div>
            <style jsx>
                {`
                    :global(.blog-card__excerpt p) {
                        margin-top: 0;
                    }
                    .blog-card {
                        box-shadow: var(--box-shadow);
                        background: #fff;
                        padding: 30px;
                        border-radius: 8px;
                        max-height: fit-content;
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .blog-card {
                            flex-basis: 48%;
                        }
                    }
                    .blog-card__title {
                        color: var(--interactive);
                        text-decoration: underline;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                    .blog-card__excerpt {
                        text-overflow: ellipsis;
                        white-space: wrap;
                        overflow: hidden;
                        margin: 15px 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .blog-card__date {
                        font-size: 12px;
                        color: var(--gray-400);
                    }
                `}
            </style>
        </div>
    );
};
export default BlogCard;
